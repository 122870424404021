import CryptoJS from "crypto-js";
import { logoutAndRedirectToLogin } from "@/services/fileUploadApi";

const key = process.env.VUE_APP_LOCAL_STORAGE_ENCRYPTION_KEY;

const Encrypt = (data) => {
  return CryptoJS.AES.encrypt(data, key).toString();
};
const Decrypt = (encryptedText) => {
  return CryptoJS.AES.decrypt(encryptedText, key).toString(CryptoJS.enc.Utf8);
};

const encryptPersistStore = (store) => {
  const unsubscribe = store.subscribe((mutation, state) => {
    const sanitizedState = JSON.stringify(state);
    const encryptedStore = Encrypt(sanitizedState);
    localStorage.setItem("eoijsd", encryptedStore);
  });

  store.$unsubscribeStore = unsubscribe;

  const persistedState = localStorage.getItem("eoijsd");
  if (persistedState) {
    try {
      const decryptedStore = Decrypt(persistedState);
      store.replaceState(JSON.parse(decryptedStore));
    } catch (e) {
      localStorage.removeItem("eoijsd");
      logoutAndRedirectToLogin();
    }
  }

  return () => {
    if (store.$unsubscribeStore) {
      store.$unsubscribeStore();
    }
  };
};

export default encryptPersistStore;
