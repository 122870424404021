import { ActionContext } from "vuex";
import Api from "@/services/api";
import { getAllInvoices, getIcomingInvoices } from "@/services/endpoints";
export default {
  namespaced: true,
  state: {
    allRecommendedInvoices: [],
    allInvoices: [],
    initailInvoices: [],
    bankNames: [],
    costCenters: [],
    yearsData: [],
    companiesData: [],
    temporaryInvoiceId: null,
    invoiceSelection: [
      {
        text: "Invoice not transfered",
        value: 1,
      },
      {
        text: "Ready for booking",
        value: 2,
      },
      {
        text: "Invoice not processed",
        value: 3,
      },
      {
        text: "No datev",
        value: 4,
      },
      {
        text: "No original copies",
        value: 5,
      },
      {
        text: "No ER-E",
        value: 6,
      },
      {
        text: "No ER-Z",
        value: 7,
      },
    ],
    loadPage: false,
    addedInvoice: null,
    rowAdded: false,
    invoiceFileType: null,
    invoiceNumberForFile: null,
    appLoading: false,
    ereFiles: [],
    erzFiles: [],
  },
  mutations: {
    SET_ALL_RECOMMENDED_INVOICES_TO_STATE(state: any, payload: any) {
      state.allRecommendedInvoices = payload;
    },
    SET_ALL_INVOICES_TO_STATE(state: any, payload: any) {
      state.allInvoices = payload;
    },
    SET_INITAIL_INVOICES_TO_STATE(state: any, payload: any) {
      state.initailInvoices = payload;
    },
    SET_BANK_NAMES_TO_STATE(state: any, payload: any) {
      state.bankNames = payload;
    },
    SET_COST_CENTERS_TO_STATE(state: any, payload: any) {
      state.costCenters = payload;
    },
    SET_YEARS_DATA_TO_STATE(state: any, payload: any) {
      state.yearsData = payload;
    },
    SET_COMPANIES_DATA_TO_STATE(state: any, payload: any) {
      state.companiesData = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_TEMPORARY_INVOICE_ID_TO_STATE(state: any, payload: any) {
      state.temporaryInvoiceId = payload;
    },
    SET_LOAD_PAGE(state: any, payload: any) {
      state.loadPage = payload;
    },
    SET_ADDED_INVOICE(state: any, payload: any) {
      state.addedInvoice = payload;
    },
    SET_ROW_ADDED(state: any, payload: any) {
      state.rowAdded = payload;
    },
    SET_INVOICE_FILE_TYPE(state: any, payload: any) {
      state.invoiceFileType = payload;
    },
    SET_INVOICE_NUMBER_FOR_FILE(state: any, payload: any) {
      state.invoiceNumberForFile = payload;
    },
    SET_ERE_FILES(state: any, payload: any) {
      state.ereFiles = payload;
    },
    SET_ERZ_FILES(state: any, payload: any) {
      state.erzFiles = payload;
    },
  },
  getters: {
    appLoading(state: any) {
      return state.appLoading;
    },
    isAllRecommendedInvoices(state: any) {
      return state.allRecommendedInvoices;
    },
    isAllInvoices(state: any) {
      return state.allInvoices;
    },
    isInitailInvoices(state: any) {
      return state.initailInvoices;
    },
    isBankNames(state: any) {
      return state.bankNames;
    },
    isCostCenters(state: any) {
      return state.costCenters;
    },
    isYearsData(state: any) {
      return state.yearsData;
    },
    isCompaniesData(state: any) {
      return state.companiesData;
    },
    isTemporaryInvoiceId(state: any) {
      return state.temporaryInvoiceId;
    },
    isInvoiceSelection(state: any) {
      return state.invoiceSelection;
    },
    isLoadPage(state: any) {
      return state.loadPage;
    },
    isAddedInvoice(state: any) {
      return state.addedInvoice;
    },
    isRowAdded(state: any) {
      return state.rowAdded;
    },
    isInvoiceFileType(state: any) {
      return state.invoiceFileType;
    },
    isInvoiceNumberForFile(state: any) {
      return state.invoiceNumberForFile;
    },
    isEreFiles(state: any) {
      return state.ereFiles;
    },
    isErzFiles(state: any) {
      return state.erzFiles;
    },
  },
  actions: {
    getAllInvoicesData(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      const payloadSearchQuery = payload.search_query
        ? payload.search_query
        : "";
      const payloadCompany = payload.company ? payload.company : "";
      const payloadYear = payload.year ? payload.year : "";
      const payloadMonth = payload.month ? payload.month : "";
      const payloadInvoiceType = payload.invoice_type
        ? payload.invoice_type
        : "";
      const payloadOrderNumber = payload.order_number
        ? payload.order_number
        : 2;
      Api()
        .get(
          `${getAllInvoices}?search=${payloadSearchQuery}&company=${payloadCompany}&year=${payloadYear}&month=${payloadMonth}&filters=${payloadInvoiceType}&order_by=${payloadOrderNumber}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_ALL_INVOICES_TO_STATE", response.data.data);
            context.commit("SET_INITAIL_INVOICES_TO_STATE", response.data.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_BANK_NAMES_TO_STATE",
              response.data.banks.map((bank: any) => {
                return {
                  text: bank.bank_name,
                  value: bank.id,
                };
              })
            );
            context.commit(
              "SET_COST_CENTERS_TO_STATE",
              response.data.cost_centers.map((center: any) => {
                return {
                  text: center.cost_center_number,
                  value: center.id,
                };
              })
            );
            context.commit(
              "SET_YEARS_DATA_TO_STATE",
              response.data.years.map((year: any) => {
                return {
                  text: year.year,
                  value: year.year,
                };
              })
            );
            context.commit(
              "SET_COMPANIES_DATA_TO_STATE",
              response.data.companies.map((company: any) => {
                return {
                  text: company.title,
                  value: company.id,
                };
              })
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getIncomingTemporaryInvoice(
      context: ActionContext<any, any>,
      payload: any
    ) {
      // this.isAppLoading = true;
      if (payload.load_page) {
        context.commit("SET_LOAD_PAGE", true);
      }
      Api()
        .get(`${getIcomingInvoices}`)
        .then((response) => {
          if (response.data.status === "success") {
            // this.isAppLoading = false;
            context.commit("SET_LOAD_PAGE", false);
            const responseData = response.data.data;
            const modifiedData = responseData?.map((item: any) => {
              return {
                invoice_number: item.invoice_number,
                request_type: item.invoice_type_display,
                date: item.date_requested?.formattedDate(),
                supplier: item.supplier,
                currency: item.currency || "-",
                cost_center: item.cost_center_number,
                requested: item.amount,
                cost_center_id: item.cost_center,
                url_invoice_type: item.url_invoice_type,
                request_id: item.request_id,
                invoice_type: item.invoice_type,
              };
            });
            context.commit(
              "SET_ALL_RECOMMENDED_INVOICES_TO_STATE",
              modifiedData
            );
          }
        })
        .catch((error) => {
          // this.isAppLoading = false;
          context.commit("SET_LOAD_PAGE", false);
          if (
            error.response.data.detail === "Unauthorized to perform this action"
          ) {
            // this.$router.push({ name: "accessDenied" });
          }
        });
    },
  },
};
