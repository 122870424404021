import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getInternalMissionPlan,
  getSingleInternalMissionPlan,
  searchInternalMissionPlan,
} from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    appLoading: false,
    allInternalMissionsPlans: [],
    currentInternalMissionsPlan: {},
    currentInternalMissionsPlanId: null,
    internalMissionsPlanUpdate: {},
    headers: [
      { key: "id", label: "Mission ID" },
      { key: "cost_center", label: "Cost Center" },
      { key: "employee", label: "Employee" },
      { key: "location", label: "Location", type: "list" },
      { key: "period", label: "Period" },
      { key: "working_days", label: "Working Days" },
      { key: "payment_date", label: "Payment Date" },
      { key: "double_taxation", label: "Double Taxation" },
      { key: "foreign_establishment", label: "Foreign Establishment" },
      { key: "status", label: "Status" },
    ],
    entertainmentHeaders: [
      {
        key: "cost_center",
        label: "Cost Center",
      },
      {
        key: "requester",
        label: "Requestee",
      },
      {
        key: "no_of_invitees",
        label: "No. of Invitees",
      },
      {
        key: "amount_before_tax",
        label: "Amt. before tax",
        type: "currency",
      },
      {
        key: "amount_after_tax",
        label: "Amt. after tax",
        type: "currency",
      },
    ],
    hotelCostsData: [],
    travelAndUtilitiesData: [],
    mileageAllowanceData: [],
    mealExpensesData: [],
    entertainmentData: [],
    basicInfo: [],
    savedChanges: false,
  },
  mutations: {
    SET_INTERNAL_MISSION_PLAN_TO_STATE(state: any, payload: any) {
      state.allInternalMissionsPlans = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENT_INTERNAL_MISSION_PLAN_TO_STATE(state: any, payload: any) {
      state.currentInternalMissionsPlan = payload;
    },
    SET_CURRENT_INTERNAL_MISSION_PLAN_ID_TO_STATE(state: any, payload: any) {
      state.currentInternalMissionsPlanId = payload;
    },

    SET_INTERNAL_MISSION_PLAN_UPDATE(state: any, payload: any) {
      state.hospitalityPackageUpdate = {
        ...state.hospitalityPackageUpdate,
        ...payload,
      };
    },
    SET_HOTEL_COSTS_DATA(state: any, payload: any) {
      state.hotelCostsData = payload;
    },
    SET_TRAVELS_AND_UTILITIES_DATA(state: any, payload: any) {
      state.travelAndUtilitiesData = payload;
    },
    SET_MILEAGE_ALLOWANCES_DATA(state: any, payload: any) {
      state.mileageAllowanceData = payload;
    },
    SET_MEAL_EXPENSES_DATA(state: any, payload: any) {
      state.mealExpensesData = payload;
    },
    SET_ENTERTAINMENT_DATA(state: any, payload: any) {
      // if (!payload) return
      // state.entertainmentData.push(payload);
      state.entertainmentData = payload;
    },
    SET_SAVED_CHANGES(state: any, payload: any) {
      state.savedChanges = payload;
    },
    SET_BASIC_INFO_TO_STATE(state: any, payload: any) {
      state.basicInfo = payload;
    },
  },
  getters: {
    allInternalMissionsPlans(state: any) {
      return state.allInternalMissionsPlans;
    },
    headers(state: any) {
      return state.headers;
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    currentInternalMissionsPlan(state: any) {
      return state.currentInternalMissionsPlan;
    },
    currentInternalMissionsPlanId(state: any) {
      return state.currentInternalMissionsPlanId;
    },
    budgetItems(state: any) {
      return state.budgetItems;
    },
    internalMissionsPlanUpdate(state: any) {
      return state.internalMissionsPlanUpdate;
    },

    getHotelCostsData(state: any) {
      return state.hotelCostsData;
    },
    getTravelAndUtilitiesData(state: any) {
      return state.travelAndUtilitiesData;
    },
    getMileageAllowanceData(state: any) {
      return state.mileageAllowanceData;
    },
    getMealExpensesData(state: any) {
      return state.mealExpensesData;
    },
    getEntertainmentData(state: any) {
      return state.entertainmentData;
    },
    getEntertainmentHeaders(state: any) {
      return state.entertainmentHeaders;
    },
    getSavedChanges(state: any) {
      return state.savedChanges;
    },
    getBasicInfo(state: any) {
      return state.basicInfo;
    },
  },
  actions: {
    getInternalMissionPlan(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getInternalMissionPlan}${payload.type}/${payload.page_number}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_INTERNAL_MISSION_PLAN_TO_STATE",
              response.data.data
            );

            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    searchInternalMissionPlan(context: ActionContext<any, any>, payload: any) {
      Api()
        .post(
          `${searchInternalMissionPlan}${context.getters.isCurrentPage}/`,
          payload
        )
        .then((response) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_INTERNAL_MISSION_PLAN_TO_STATE",
              response.data.data
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },
    getSingleInternalMissionPlan(
      context: ActionContext<any, any>,
      payload: any
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getSingleInternalMissionPlan}${context.getters.currentInternalMissionsPlanId}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit(
              "SET_CURRENT_INTERNAL_MISSION_PLAN_TO_STATE",
              responseData
            );
            context.commit("SET_BASIC_INFO_TO_STATE", responseData.basic_info);
            context.commit(
              "SET_ENTERTAINMENT_DATA",
              responseData.entertainment_packages
            );
            context.commit("SET_HOTEL_COSTS_DATA", responseData.hotel_costs);
            context.commit(
              "SET_MEAL_EXPENSES_DATA",
              responseData.meal_expenses
            );
            context.commit(
              "SET_TRAVELS_AND_UTILITIES_DATA",
              responseData.travel_and_utilities
            );
            context.commit(
              "SET_MILEAGE_ALLOWANCES_DATA",
              responseData.milleage_allowances
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
  },
};
