import Api from "@/services/api";
// import { extractUnderscore } from "@/services/helpers";
import { ActionContext } from "vuex";
import { getBusinessAreas, getAllDonors } from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    appLoading: false,
    currentPage: 1,
    totalPages: null,
    totalData: null,
    taskAreas: [],
    jobTypes: [],
    employmentTypes: [],
    teams: [],
    tabId: 0,
    costCenters: [],
    positions: [],
    companies: [],
    languages: [],
    businessArea: [],
    allBusinessArea: [],
    allCostItems: [],
    allNonAcquictionCompanies: [],
    iconCategory: [],
    defaultCollaborators: [],
    isConfigurationsUpdate: {},
    costCenterNumber: "",
    addAllToArray: { text: "All", value: "All" },
    projectCostCenters: [],
    allDonors: [],
  },

  mutations: {
    SET_PROJECT_COST_CENTERS(state: any, payload: any) {
      state.projectCostCenters = payload;
    },
    SET_TASK_AREAS_TO_STATE(state: any, payload: any) {
      state.taskAreas = payload;
    },
    SET_JOB_TYPES_TO_STATE(state: any, payload: any) {
      state.jobTypes = payload;
    },
    SET_EMPLOYMENT_TYPES_TO_STATE(state: any, payload: any) {
      state.employmentTypes = payload;
    },
    SET_TEAMS_TO_STATE(state: any, payload: any) {
      state.teams = payload;
    },
    SET_CURRENT_TAB_TO_STATE(state: any, payload: any) {
      state.tabId = payload;
    },
    SET_COMPANIES_TO_STATE(state: any, payload: any) {
      state.companies = payload;
    },
    SET_POSITIONS_TO_STATE(state: any, payload: any) {
      state.positions = payload;
    },
    SET_COST_CENTERS_TO_STATE(state: any, payload: any) {
      state.costCenters = payload;
    },
    SET_LANGUAGES_TO_STATE(state: any, payload: any) {
      state.languages = payload;
    },
    SET_BUSINESS_AREA_TO_STATE(state: any, payload: any) {
      state.businessArea = payload;
    },
    SET_ALL_BUSINESS_AREA_TO_STATE(state: any, payload: any) {
      state.allBusinessArea = payload;
    },
    SET_COST_CENTERS_NUMBER(state: any, payload: any) {
      state.costCenterNumber = payload;
    },
    SET_ICON_CATEGORY_TO_STATE(state: any, payload: any) {
      state.iconCategory = payload;
    },
    SET_COST_CENTERS_ALL_ITEM_TO_STATE(state: any, payload: any) {
      state.allCostItems = payload;
    },
    SET_DEFAULT_COLLABORATORS_TO_STATE(state: any, payload: any) {
      state.defaultCollaborators = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CONFIGURATIONS_UPDATE(state: any, payload: any) {
      state.isConfigurationsUpdate = {
        ...state.isConfigurationsUpdate,
        ...payload,
      };
    },
    SET_ALL_NON_ACQUISITION_COMPANIES(state: any, payload: any) {
      state.allNonAcquictionCompanies = payload;
    },
    SET_ALL_DONORS_TO_STATE(state: any, payload: any) {
      state.allDonors = payload;
    },
    SET_APP_LOADING(state: any, payload: any) {
      state.appLoading = payload;
    },
  },
  getters: {
    allProjectCostCenters(state: any) {
      return state.projectCostCenters.slice();
    },
    isTaskAreas(state: any) {
      return state.taskAreas;
    },
    isCurrentTab(state: any) {
      return state.tabId;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isJobTypes(state: any) {
      return state.jobTypes;
    },
    isEmploymentTypes(state: any) {
      return state.employmentTypes;
    },
    isTeams(state: any) {
      return state.teams;
    },
    isPositions(state: any) {
      return state.positions;
    },
    isCompanies(state: any) {
      return state.companies;
    },
    isCostCenters(state: any) {
      return state.costCenters;
    },
    isAllCostItems(state: any) {
      return state.allCostItems;
    },
    isLanguages(state: any) {
      return state.languages;
    },
    isBusinessArea(state: any) {
      return state.businessArea;
    },
    businessAreasSingleSelectDropdown(state: any) {
      return [
        state.addAllToArray,
        ...state.businessArea.map((area: any) => ({
          text: area.title,
          value: area.id,
        })),
      ];
    },
    isCostCenterNumber(state: any) {
      return state.costCenterNumber;
    },
    isAllBusinessArea(state: any) {
      return state.allBusinessArea;
    },
    isIconCategory(state: any) {
      return state.iconCategory;
    },
    isDefaultCollaborators(state: any) {
      return state.defaultCollaborators;
    },
    isLanguagesTitle(state: any) {
      return state.languages.map((language: any) => language.title);
    },
    isCompaniesTitle(state: any) {
      const getData = state.companies.map((company: any) => {
        return { id: company.id, text: company.title, value: company.title };
      });
      return [state.addAllToArray, ...getData];
    },
    isPositionsTitle(state: any) {
      const getData = state.positions.map((position: any) => {
        return { text: position.title, value: position.title };
      });
      return [state.addAllToArray, ...getData];
    },
    isTeamsTitle(state: any) {
      const getData = state.teams?.map((team: any) => {
        return { id: team.id, text: team.title, value: team.title };
      });
      return [state.addAllToArray, ...getData];
    },
    isBusinessAreaTitle(state: any) {
      return state.businessArea.map((area: any) => area.title);
    },
    isIconCategoryTitle(state: any) {
      return state.iconCategory.map((category: any) => category.title);
    },
    isEmploymentTypesTitle(state: any) {
      const getData = state.employmentTypes.map((employeeType: any) => {
        return { text: employeeType.title, value: employeeType.title };
      });
      return [state.addAllToArray, ...getData];
    },
    isConfigurationsUpdate(state: any) {
      return state.isConfigurationsUpdate;
    },
    isAllNonAcquisitionCompanies(state: any) {
      return state.allNonAcquictionCompanies;
    },
    getAllDonors(state: any) {
      return state.allDonors;
    },
    getAppLoading(state: any) {
      return state.appLoading;
    },
  },
  actions: {
    getAllConfigurationLanguages(
      context: ActionContext<any, any>,
      payload: any
    ) {
      Api()
        .get(`/configuration/general/get-general/Languages/${payload}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_LANGUAGES_TO_STATE", responseData.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              responseData.current_page
            );
            context.commit("SET_TOTAL_PAGES_TO_STATE", responseData.total_data);
          }
        });
    },
    getAllConfigurationTeams(
      context: ActionContext<any, any>,
      payload: any = null
    ) {
      const endpoint = payload
        ? `/configuration/general/get-general/Teams/${payload}/`
        : "/configuration/general/get-general/Teams/";
      Api()
        .get(endpoint)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_TEAMS_TO_STATE", responseData.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              responseData.current_page
            );
            context.commit("SET_TOTAL_PAGES_TO_STATE", responseData.total_data);
          }
        });
    },
    getAllConfigurationCompanies(
      context: ActionContext<any, any>,
      payload: any = null
    ) {
      const endpoint = payload
        ? `/configuration/general/get-general/Companies/${payload}/`
        : "/configuration/general/get-general/Companies/";
      Api()
        .get(endpoint)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_COMPANIES_TO_STATE", responseData.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              responseData.current_page
            );
            context.commit("SET_TOTAL_PAGES_TO_STATE", responseData.total_data);
          }
        });
    },
    getAllConfigurationPositions(
      context: ActionContext<any, any>,
      payload: any = null
    ) {
      const endpoint = payload
        ? `/configuration/general/get-general/Positions/${payload}/`
        : "/configuration/general/get-general/Positions/";
      Api()
        .get(endpoint)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_POSITIONS_TO_STATE", responseData.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              responseData.current_page
            );
            context.commit("SET_TOTAL_PAGES_TO_STATE", responseData.total_data);
          }
        });
    },
    getAllConfigurationEmploymentTypes(
      context: ActionContext<any, any>,
      payload: any
    ) {
      Api()
        .get(`/configuration/general/get-general/Employment Types/${payload}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_POSITIONS_TO_STATE", responseData.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              responseData.current_page
            );
            context.commit("SET_TOTAL_PAGES_TO_STATE", responseData.total_data);
          }
        });
    },
    getAllConfigurationTypes(
      context: ActionContext<any, any>,
      payload: {
        configType: string;
        setter: string;
      }
    ) {
      Api()
        .get(`/configuration/general/get-general/${payload.configType}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit(payload.setter, responseData.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              responseData.current_page
            );
            context.commit("SET_TOTAL_PAGES_TO_STATE", responseData.total_data);
          }
        });
    },
    getAllDefaultCollaborators(context: ActionContext<any, any>) {
      Api()
        .get("/configuration/default-collaborators/get-responsible-persons/")
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit(
              "SET_DEFAULT_COLLABORATORS_TO_STATE",
              responseData.data
            );
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              responseData.current_page
            );
            context.commit("SET_TOTAL_PAGES_TO_STATE", responseData.total_data);
          }
        });
    },

    getAllCostCenters(context: ActionContext<any, any>, page: any) {
      Api()
        .post(`/configuration/projects/all-cost-centers/${page}/`)
        .then((response) => {
          const payload = response.data;
          context.commit("SET_COST_CENTERS_TO_STATE", payload.data);
          context.commit("SET_CURRENT_PAGE_TO_STATE", payload.current_page);
          context.commit("SET_TOTAL_PAGES_TO_STATE", payload.total_data);
        });
    },
    getAllCostCentersWithoutPagination(
      context: ActionContext<any, any>,
      payload: string
    ) {
      Api()
        .get(`/configuration/projects/all-cost-centers/?${payload}`)
        .then((response) => {
          const payload = response.data;
          context.commit("SET_COST_CENTERS_TO_STATE", payload.data);
        });
    },
    getAllAvailableCostCenters(context: ActionContext<any, any>) {
      Api()
        .get(`/configuration/projects/all-available-cost-centers/`)
        .then((response) => {
          const payload = response.data;
          context.commit("SET_COST_CENTERS_TO_STATE", payload.data);
        });
    },

    getCostCenters(context: ActionContext<any, any>) {
      Api()
        .get(`/configuration/projects/all-cost-centers/`)
        .then((response) => {
          const payload = response.data;
          context.commit("SET_COST_CENTERS_ALL_ITEM_TO_STATE", payload.data);
        });
    },

    getAllBusinessAreas(context: ActionContext<any, any>) {
      Api()
        .get(getBusinessAreas)
        .then((response) => {
          const payload = response.data;

          context.commit("SET_BUSINESS_AREA_TO_STATE", payload.data);
          context.commit("SET_ALL_BUSINESS_AREA_TO_STATE", payload.data);
        });
    },

    getAllCostCentersLinkedToProjects(context: ActionContext<any, any>) {
      Api()
        .get("/configuration/projects/project-cost-centres/")
        .then((response) => {
          context.commit("SET_PROJECT_COST_CENTERS", response.data.data);
        })
        .catch((error: any) => {});
    },
    fetchAllDonors(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING", true);
      }
      Api()
        .get(getAllDonors)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING", false);
            context.commit("SET_ALL_DONORS_TO_STATE", response.data.data);
          }
        })
        .catch((error: any) => {
          if (error.response) {
            context.commit("SET_APP_LOADING", false);
          }
        });
    },
  },
};
